import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Listing, SliceZone, SEO } from '../components'
import CategoriesText from '../components/Listing/CategoriesText'
import website from '../../config/website'
import { LocaleContext } from '../components/layout/layout'
import { H1, H1Content, P } from '../styles/common/Typo'
import { Container, Section } from '../styles/common/Layout'
import tw from 'twin.macro'

const Post = ({ data: { prismicBlogPost, posts }, location, pageContext: { locale } }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const { data } = prismicBlogPost

  let categories = false
  if (data.categories[0].category) {
    categories = data.categories.map(c => c.category.document.data.name)
  }

  return (
    <>
      <Section id="section__blog__post" SmallPadding>
        <Container>
          <SEO
            title={`${data.title.text} | Blog | ${i18n.defaultTitleAlt}`}
            pathname={location.pathname}
            locale={locale}
            desc={data.description}
            node={prismicBlogPost}
            article
          />
          <P><time>{data.date}</time> - {categories && <CategoriesText categories={categories} />}</P>
          <H1>{data.title.text}</H1>

          <SliceZone allSlices={data.body} />
        </Container>
      </Section>
      <Section id="section__blog__recent" Color="White" SmallPadding>
        <Container>
          <H1>{i18n.recent} Posts</H1>
          <Listing posts={posts.edges} />
        </Container>
      </Section>

    </>
  )
}
export default Post
Post.propTypes = {
  data: PropTypes.shape({
    prismicBlogPost: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}
// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you
export const pageQuery = graphql`
  query PostBySlug($uid: String!, $locale: String!) {
    prismicBlogPost(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      first_publication_date
      last_publication_date
      data {
        title {
          text
        }
        description
        date(formatString: "DD.MM.YYYY")
        categories {
          category {
            document {
              ... on PrismicCategory {
                id
                data {
                  name
                }
              }
            }
          }
        }
        body {
          ... on PrismicBlogPostBodyText {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicBlogPostBodyCodeBlock {
            slice_type
            id
            primary {
              code_block {
                html
                raw
              }
            }
          }
          ... on PrismicBlogPostBodyQuote {
            slice_type
            id
            primary {
              quote {
                html
                text
              }
            }
          }
          ... on PrismicBlogPostBodyImage {
            slice_type
            id
            primary {
              image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
    }
    posts: allPrismicBlogPost(limit: 2, sort: { fields: [data___date], order: DESC }, filter: { lang: { eq: $locale } }) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            date(formatString: "DD.MM.YYYY")
            categories {
              category {
                document {
                  ... on PrismicCategory {
                    id
                    data {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const StyledWrapper = tw.div`
  flex
  flex-col
  md:flex-row
`;

const StyledSectionLeft = tw.div`
  p-0
  pt-6
  w-full
  md:p-6
  md:w-1/2
  md:block
`;

const StyledSectionRight = tw.div`
  p-0
  w-full
  flex
  flex-col
  md:p-6
  md:w-1/2
`;